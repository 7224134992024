<template>
  <div class="container">
    <div style="height: 300px">
      <gd_map @initMap="initMap"/>
    </div>
    <div class="header">
      <van-cell-group>
        <van-cell title="订单号" style="font-size: 12px">{{ order.orderNo }}</van-cell>
        <van-cell title="出发地">{{ order.startAddress }}</van-cell>
        <van-cell title="目的地">{{ order.endAddress }}</van-cell>
        <van-cell title="乘车人姓名">{{ order.passengerName }}</van-cell>
        <van-cell title="乘车人手机号">{{ order.passengerPhone }}</van-cell>
        <van-cell title="乘车人身份证号">{{ order.passengerCardNo }}</van-cell>
        <van-cell title="出发时间">{{ order.startTime }}</van-cell>
        <van-cell title="订单状态">{{ order.payStatus ? '已支付' : '未支付' }}</van-cell>
      </van-cell-group>
      <van-submit-bar v-if="order.payStatus === false" label="总金额 :"
                      tip="仅支持支付宝支付,请确定手机上已安装支付宝!"
                      :price="order.payPrice * 100"
                      :loading="loading"
                      button-text="去支付"
                      @submit="onPay(order.orderNo)"/>
    </div>


  </div>
</template>

<script>
import * as orderApi from "@/api/order";
import gd_map from "@/components/map/index";
import {Toast} from "vant";
import html2canvas from 'html2canvas';

export default {
  name: 'DriveOrderInfo',
  components: {gd_map},
  data() {
    return {
      order: {
        orderNo: '',
        startAddress: '',
        endAddress: '',
        passengerName: '',
        passengerPhone: '',
        passengerCardNo: '',
        startTime: '',
        payStatus: true
      },
      driving: null,
      loading: true
    }
  },
  created() {
    this.getOrderInfo();
  },
  methods: {
    initMap(driving) {
      this.driving = driving;
    },
    getOrderInfo() {
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'circular',
        duration: 0
      });
      let orderNo = this.$route.params.orderNo;
      console.log('参数信息:', orderNo);
      if (orderNo) {
        let that = this;
        orderApi.driveOrderInfo(orderNo).then(res => {
          that.order = res.data;
          toast.clear();
          that.loading = false;
          //为防止地图可能未加载好的情况,地图延迟1秒规划路线
          setTimeout(() => {
            // 根据起终点经纬度规划驾车导航路线
            that.driving.search([{keyword: res.data.startAddress, city: '杭州'}, {
              keyword: res.data.endAddress,
              city: '杭州'
            }], function (status) {
              if (status === 'complete') {
                console.log('绘制驾车路线完成')
                setTimeout(() => {
                  that.handleImage();
                }, 9500);
              } else {
                console.log('获取驾车数据失败：' + status)
              }
            });
          }, 1000);
        })
      } else {
        toast.clear();
        that.loading = false;
        Toast.fail("订单信息错误");
      }
    },
    onPay(orderNo) {
      let data = {
        orderNo: orderNo,
        returnUrl: window.location.origin + "/drivePayResult",
      };
      orderApi.driveOrderPay(data).then(res => {
        console.log("h5支付");
        let divForm = document.getElementsByTagName('divform');
        if (divForm.length) {
          document.body.removeChild(divForm[0]);
        }
        const div = document.createElement('divform');
        div.innerHTML = res.data; // res.data就是支付宝返回给你的form
        document.body.appendChild(div);
        document.forms[0].submit();
        div.remove();
      })
    },
    handleImage() {
      const mapContainer = document.getElementById('container');
      html2canvas(mapContainer,{
        logging: true,
        useCORS: true,
        foreignObjectRendering: false,
        allowTaint: false,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png',1);
        let a = document.createElement("a");
        a.setAttribute("href", imgData);
        a.setAttribute("download", "image.png");
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  background-color: #F7F7F7;

  .header {
    // padding: 0 25px;
    background-color: #fff;
  }

  .content {
    margin-top: 20px;
    padding: 0 50px;
    background-color: #fff;
  }
}
</style>
